import { Inject, Injectable } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Params, Router } from '@angular/router';
import {
  Charity,
  Design,
  DonationSource,
  DonorFlowStepConfig,
  ENVIRONMENT,
  Environment,
  FlowStep,
  FlowTypes,
  Lead,
  LeadChannel,
  LeadUTM,
  OwnerType,
  Specification
} from '@domains';
import { AppService } from '@donor/app.service';
import { Client, createInstance } from '@optimizely/optimizely-sdk';
import { IOptimizelyUserContext } from '@optimizely/optimizely-sdk/dist/optimizely_user_context';
import { LeadsService, LocalStorageService } from '@rspl-api';
import { DesignService } from '@rspl-ui';
import * as moment from 'moment';
import { from, Observable, of, switchMap, take, tap } from 'rxjs';

export const LEAD_ID = 'rspl-lead-id';
export const LEAD_ID_TIMESTAMP = 'rspl-lead-id-timestamp';
export const LEAD_FLOW = 'rspl-lead-flow';
export const LEAD_FLOW_ID = 'rspl-lead-id-flow';
export const SPLIT_SCREEN_VISITED = 'rspl-split-screen-visited';

@Injectable({
  providedIn: 'root',
})
export class FlowService {
  lead: Lead;
  paymentIntentTimeout;
  paymentIntent?: {
    booking_fee: boolean;
    client_secret: string;
  };

  optimizelyMarketingData: {
    [key: string]: any;
  } = {};
  optimizely: Client;
  optimizelyUser: IOptimizelyUserContext;
  optimizelyFlowData: {
    flowConfiguration: DonorFlowStepConfig[];
    design: Design;
  } = {
    flowConfiguration: [
      {
        step: FlowStep.SplitScreen,
        component: 'FlowSplitScreenComponent',
      },
      {
        step: FlowStep.DonationDetails,
        component: 'FlowDonationDetailsComponent',
        stepperTitle: 'Items',
      },
      {
        step: FlowStep.PlaceTime,
        component: 'FlowPlaceTimeComponent',
        stepperTitle: 'Schedule',
      },
      {
        step: FlowStep.AdditionalInformation,
        component: 'FlowAdditionalInformationComponent',
        stepperTitle: 'Information',
      },
      {
        step: FlowStep.Payment,
        component: 'FlowPaymentComponent',
        stepperTitle: 'Payment',
      },
    ],
    design: Design.DESIGN_2,
  };

  constructor(
    private leadService: LeadsService,
    protected localStorage: LocalStorageService,
    private router: Router,
    @Inject(ENVIRONMENT) private environment: Environment,
    protected sanitizer: DomSanitizer,
    private designService: DesignService,
    private appService: AppService
  ) {}

  initLead(
    flowType: FlowTypes,
    flowId: string,
    flowStep: FlowStep,
    charity?: Charity,
    queryParams?: Params
  ) {
    const hasUrlLeadId = !!queryParams['lead_id'];
    if (hasUrlLeadId) {
      this.reset();
    } else {
      const lsFlowType = this.localStorage.getItem(LEAD_FLOW)
        ? (this.localStorage.getItem(LEAD_FLOW) as FlowTypes)
        : undefined;
      const lsFlowId = this.localStorage.getItem(LEAD_FLOW_ID);
      if (
        (lsFlowType && lsFlowType !== flowType) ||
        (lsFlowId && lsFlowId !== flowId)
      ) {
        this.reset();
      }
    }
    let leadId =
      queryParams['lead_id'] || this.localStorage.getItem(LEAD_ID)?.toString();
    let timestamp;
    const curr = moment();
    const tmp = leadId
      ? moment(this.localStorage.getItem(LEAD_ID_TIMESTAMP))
      : null;

    if (tmp) {
      timestamp = moment(tmp).add(1, 'hour');
    }
    if (!hasUrlLeadId && (!timestamp || timestamp.isBefore(curr))) {
      this.reset();
      leadId = undefined;
    }
    this.localStorage.setItem(LEAD_FLOW, flowType);
    this.localStorage.setItem(LEAD_FLOW_ID, flowId);

    return (
      leadId
        ? this.leadService.find(leadId)
        : this.createLead(flowType, flowId, charity, queryParams)
    ).pipe(
      switchMap((lead) => {
        if (
          !lead ||
          lead.donationCode ||
          (flowType &&
            ((flowType === FlowTypes.CHARITY &&
              flowId &&
              lead?.charityId !== flowId) ||
              (flowType === FlowTypes.PARTNER &&
                flowId &&
                lead?.partnerId !== flowId)))
        ) {
          this.reset();
          let qp = { ...queryParams };
          delete qp['lead_id'];
          this.router.navigate([flowStep, flowType, flowId], {
            queryParams: qp,
          });
          setTimeout(() => {
            location.reload();
          });
          return null;
        }
        this.localStorage.setItem(LEAD_ID, lead.id);
        this.localStorage.setItem(LEAD_ID_TIMESTAMP, moment().toISOString());
        if (!flowType) {
          // handle card-on-file route
          this.designService.setDesign(
            (lead.meta.design === 'v1'
              ? Design.DESIGN_1
              : ['v2', 'v3'].includes(lead.meta.design)
              ? Design.DESIGN_2
              : undefined) || Design.DESIGN_2
          );
          this.lead = lead;
          return of(lead);
        }
        return from(
          this.initOptimizely(
            lead,
            queryParams['optimizely_test'],
            charity?.marketId
          )
        ).pipe(
          switchMap((optimizelyRes) => {
            let shouldUpdate = false;
            //check marketing values
            if (
              optimizelyRes &&
              (lead.meta.marketingRule !== optimizelyRes.marketing.rule ||
                lead.meta.marketingVariation !==
                  optimizelyRes.marketing.variation)
            ) {
              lead.meta.marketingRule = optimizelyRes.marketing.rule;
              lead.meta.marketingVariation = optimizelyRes.marketing.variation;
              shouldUpdate = true;
            }
            if (
              optimizelyRes &&
              (lead.meta.flowRule !== optimizelyRes.flowConfiguration.rule ||
                lead.meta.flowVariation !==
                  optimizelyRes.flowConfiguration.variation)
            ) {
              lead.meta.flowRule = optimizelyRes.flowConfiguration.rule;
              lead.meta.flowVariation = optimizelyRes.flowConfiguration.variation;
              shouldUpdate = true;
            }            

            //check design version
            let designVersion;
            switch (optimizelyRes.flowConfiguration.variables.design) {
              case Design.DESIGN_1:
                designVersion = 'v1';
                break;
              case Design.DESIGN_2:
              default:
                if (lead.charity?.meta?.onlySmallItems) {
                  designVersion = 'v3';
                } else {
                  designVersion = 'v2';
                }
                break;
            }
            this.designService.setDesign(
              optimizelyRes.flowConfiguration.variables.design
            );
            if (lead.meta.design !== designVersion) {
              lead.meta.design = designVersion;
              shouldUpdate = true;
            }
            if (shouldUpdate) {
              return this.updateLead(lead);
            } else {
              this.lead = lead;
              return of(lead);
            }
          })
        );
      }),
      take(1)
    );
  }

  createLead(
    flowType: FlowTypes,
    flowId: string,
    charity: Charity,
    queryParams?: Params
  ): Observable<Lead> {
    const lead: Partial<Lead> = {
      charityId: flowType === FlowTypes.CHARITY ? flowId : undefined,
      partnerId: flowType === FlowTypes.PARTNER ? flowId : undefined,
      channel: LeadChannel.donor_app,
      ...(charity?.meta?.onlySmallItems
        ? {
            specification: new Specification({
              small: 5,
            }),
          }
        : {}),
      meta: {
        source:
          flowType === FlowTypes.CHARITY
            ? DonationSource.charity
            : flowType === FlowTypes.PARTNER
            ? DonationSource.partner
            : flowType === FlowTypes.TERRITORY && flowId !== '1'
            ? DonationSource.territory
            : DonationSource.resupply,
        manualAddress: false,
        requestCancel: false,
      },
      marketingSource:
        (flowType === FlowTypes.CHARITY
          ? DonationSource.charity
          : flowType === FlowTypes.PARTNER
          ? DonationSource.partner
          : flowType === FlowTypes.TERRITORY && flowId !== '1'
          ? DonationSource.territory
          : DonationSource.resupply) +
        (queryParams['utm_source']
          ? '-' + queryParams['utm_source']?.toString()?.toLowerCase()
          : ''),
      utm: new LeadUTM({
        source: queryParams['utm_source']?.toString(),
        medium: queryParams['utm_medium']?.toString(),
        campaign: queryParams['utm_campaign']?.toString(),
        term: queryParams['utm_term']?.toString(),
        content: queryParams['utm_content']?.toString(),
      }),
      ...(queryParams['rsp_ot'] && queryParams['rsp_oid'] //TODO are we still using this?
        ? {
            ownerId: queryParams['rsp_oid'],
            ownerType: queryParams['rsp_ot'],
          }
        : {
            ownerType:
              flowType === FlowTypes.CHARITY
                ? OwnerType.Charity
                : flowType === FlowTypes.PARTNER
                ? OwnerType.Partner
                : OwnerType.Organization,
            ownerId: flowId,
          }),
    };
    return this.leadService.create(lead as Lead);
  }

  updateLead(data: Partial<Lead>): Observable<Lead> {
    return this.leadService
      .update(
        this.lead?.id || data?.id,
        new Lead({
          ...this.lead,
          ...data,
        })
      )
      .pipe(
        tap((lead) => {
          this.lead = lead;
          this.localStorage.setItem(LEAD_ID_TIMESTAMP, moment().toISOString());
        })
      );
  }

  reset() {
    this.localStorage.removeItem(LEAD_ID);
    this.localStorage.removeItem(LEAD_FLOW);
    this.localStorage.removeItem(LEAD_FLOW_ID);
    this.localStorage.removeItem(LEAD_ID_TIMESTAMP);
    this.localStorage.removeItem(SPLIT_SCREEN_VISITED);
    this.lead = undefined;
    this.paymentIntent = undefined;
  }

  bypassSecurityTrustHtml(html: string) {
    return this.sanitizer.bypassSecurityTrustHtml(html);
  }

  createPaymentSetup() {
    if (this.paymentIntent) {
      return of(this.paymentIntent);
    }
    return this.leadService.createPaymentSetup(this.lead).pipe(
      take(1),
      tap((paymentIntent) => {
        this.paymentIntent = paymentIntent;
        if (this.paymentIntentTimeout) {
          clearTimeout(this.paymentIntentTimeout);
        }
        this.paymentIntentTimeout = setTimeout(() => {
          this.paymentIntent = undefined;
        }, 24 * 60 * 60 * 1000);
      })
    );
  }

  async initOptimizely(lead: Lead, variation?: string, marketId?: string) {
    if (!this.environment.optimizelyKey || this.optimizelyUser) return null;
    try {
      const DATAFILE_URL = `https://cdn.optimizely.com/datafiles/${this.environment.optimizelyKey}.json`;
      const response = await fetch(DATAFILE_URL);
      const datafile = await response.json();

      this.optimizely = createInstance({
        datafile,
      });

      if (!this.optimizely) {
        console.log('Optimizely init error');
        return null;
      } else {
        this.optimizelyUser = this.optimizely.createUserContext(lead.id, {
          ...(variation ? { variation } : {}),
          ...(marketId ? { market_id: marketId } : {}),
          ...(lead.charityId ? { charity_id: lead.charityId } : {}),
          ...(lead.partnerId ? { partner_id: lead.partnerId } : {}),
          ...(lead.ownerId ? { owner_id: lead.ownerId } : {}),
          ...(lead.ownerType ? { owner_type: lead.ownerType } : {}),
        });
        this.appService.optimizelyUser = this.optimizelyUser;

        // decide and populate marketing variables
        const optimizelyMarketingDecision =
          this.optimizelyUser.decide('marketing');
        this.optimizelyMarketingData = optimizelyMarketingDecision.variables;

        // decide and populate flowConfiguration & design
        const optimizelyFlowDecision = this.optimizelyUser.decide('flow');
        this.optimizelyFlowData = {
          flowConfiguration:
            (optimizelyFlowDecision.variables['flowConfiguration'] as any)
              ?.flowConfiguration || this.optimizelyFlowData.flowConfiguration,
          design: (optimizelyFlowDecision.variables['design'] ||
            this.optimizelyFlowData.design) as Design,
        };

        return {
          marketing: {
            variation: optimizelyMarketingDecision.variationKey,
            rule: optimizelyMarketingDecision.ruleKey,
            variables: this.optimizelyMarketingData,
          },
          flowConfiguration: {
            variation: optimizelyFlowDecision.variationKey,
            rule: optimizelyFlowDecision.ruleKey,
            variables: this.optimizelyFlowData,
          },
        };
      }
    } catch (err) {
      return {
        marketing: {
          variation: undefined,
          rule: 'optimizely-error',
          variables: {},
        },
        flowConfiguration: {
          variation: undefined,
          rule: 'optimizely-error',
          variables: this.optimizelyFlowData,
        },
      };
    }
  }
}
