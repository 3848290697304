<ng-container *ngIf="design" [ngTemplateOutlet]="DESIGN_2"></ng-container>
<ng-template #DESIGN_2>
  <app-page-header
    [charity]="charity"
    [territory]="organization"
    title="Reserve your donation pickup."
    subtitle="Total estimate to be finalized on site"
    [hasBack]="hasBack && !cardOnFile"
    (onBack)="back()"
  ></app-page-header>
  <div class="page-content" fxLayout="column" fxLayoutAlign="start start">
    <div
      class="payment-instructions step-content"
      fxLayout="row"
      fxLayoutAlign="start center"
      *ngIf="!expired"
    >
      <div
        class="payment-content"
        fxLayout="column"
        fxLayout.gt-sm="row"
        fxLayoutAlign-gt-sm="start start"
        fxLayoutAlign.lt-md="start center"
        fxLayoutGap.lt-md="10px"
        fxLayoutGap.gt-sm="30px"
      >
        <div fxLayout="column" fxFlex="100" fxFlex.gt-sm="60">
          <div fxLayout="row" fxFlex="100">
            <div
              fxLayout="column"
              fxLayoutAlign="start center"
              fxFlex="100"
              *ngIf="lead"
            >
              <button
                *ngIf="!showQuoteDetails && estimate"
                class="primary-btn filled-btn quote-details-btn"
                (click)="showQuoteDetails = true"
                ngClass.gt-sm="hidden"
              >
                Show Cost Breakdown:
                {{
                  (estimate.donationPrice || 0) + (estimate.bookingFee || 0)
                    | currency
                }}
              </button>
              <div
                *ngIf="!showQuoteDetails && isSmallWindowSize"
                class="estimated-pickup-cost"
                fxFlexAlign="space-between"
              >
                <span style="margin-bottom: 4px; line-height: 14px"
                  >Estimated Pickup Cost <br />(Due On-Site)</span
                >
                <span>{{ donationPrice || 0 | currency }}</span>
              </div>
              <div
                *ngIf="!showQuoteDetails && isSmallWindowSize"
                class="todays-charges"
                fxFlexAlign="space-between"
              >
                <span>Today's Charges</span>
                <span>{{ lead.pricing.bookingFee || 0 | currency }}</span>
              </div>
              <div
                fxLayout="column"
                fxLayout.gt-xs="row"
                fxFlex="100"
                [ngClass.lt-md]="{ hidden: !showQuoteDetails }"
              >
                <rspl-donation-estimate
                  fxFlex="100"
                  *ngIf="lead && pricing && lead.specification"
                  [pricing]="pricing"
                  [specification]="lead.specification"
                  [showZeros]="false"
                  [editable]="charity?.meta?.onlySmallItems ? false : true"
                  [showUnitPrices]="false"
                  [bookingFee]="lead.pricing.bookingFee"
                  [showTodaysCharges]="true"
                  totalLabel="Estimated Pickup Cost (Due On-Site)"
                  bookingLabel="Booking Fee (Due Today)"
                >
                </rspl-donation-estimate>
              </div>
            </div>
          </div>
          <ng-container
            [ngTemplateOutlet]="Checkbox"
            *ngIf="!isSmallWindowSize"
          ></ng-container>
        </div>
        <div
          fxLayout="column"
          fxLayoutGap="15px"
          fxFlex="100"
          fxFlex.gt-sm="40"
        >
          <div class="stripe-wrapper">
            <ng-container [ngTemplateOutlet]="STRIPE"></ng-container>
            <div
              fxLayoutAlign="center center"
              fxLayout="column"
              *ngIf="isLoaded"
            >
              <button
                id="step5Submission"
                class="submit-button primary-page-btn success-btn btn-block"
                id="submitDonationButton"
                (click)="saveLead()"
                [ladda]="isSubmitting"
                [disabled]="!isLoaded"
              >
                Reserve Your Pickup
              </button>
              <div
                fxLayout="row"
                fxLayoutAlign="center center"
                class="powered-by"
              >
                <mat-icon class="lock-icon">lock</mat-icon>
                <span>Secure payments through</span>
                <mat-icon class="stripe-icon" svgIcon="stripe"></mat-icon>
              </div>
            </div>
          </div>
        </div>
        <div fxLayout="column" fxFlex="100" *ngIf="isSmallWindowSize">
          <ng-container [ngTemplateOutlet]="Checkbox"></ng-container>
        </div>
      </div>
    </div>
    <div class="error-wrapper" fxLayoutAlign="center center">
      <p class="error" *ngIf="error && !expired">{{ error }}</p>
      <p class="error expired" *ngIf="expired">
        Your link has expired, please contact support to get a new link
      </p>
    </div>

    <div
      fxLayout="column"
      fxLayout.gt-sm="row"
      class="progress-wrapper"
      *ngIf="!cardOnFile"
    >
      <app-create-progress fxFlex [step]="flowStep"></app-create-progress>
    </div>
  </div>
  <ng-template #Checkbox>
    <div fxLayout="column" class="checkboxes">
      <div
        class="form-row form-row-checkbox"
        fxLayout="row"
        fxLayoutAlign="start start"
        fxFlex.gt-sm="50"
      >
        <input
          type="checkbox"
          id="userTerms"
          name="userTerms"
          [(ngModel)]="userTerms"
        />
        <button
          class="checkbox"
          (click)="onCheckboxChange($event)"
          [ngClass]="{ invalid: !userTerms && showErrors }"
        >
          <mat-icon svgIcon="checkmark" *ngIf="userTerms"></mat-icon>
        </button>
        <label
          [ngClass]="{ invalid: !userTerms && showErrors }"
          for="userTerms"
        >
          <ng-container *ngIf="(lead.pricing.bookingFee || 0) > 0">
            I understand my card is only being charged a
            {{ lead.pricing.bookingFee || 0 | currency }} reservation fee at
            this time.
          </ng-container>
          <ng-container *ngIf="(lead.pricing.bookingFee || 0) === 0"
            >I understand my card will not be charged at this
            time.</ng-container
          >
          The final cost of the donation pickup will be charged once my
          donations have been delivered to the
          {{ (charity || lead?.charity)?.name || 'charity' }}.
          <ng-container *ngIf="(lead.pricing.bookingFee || 0) > 0">
            If I cancel my donation, the
            {{ lead.pricing.bookingFee || 0 | currency }} reservation fee is not
            refundable. If ReSupply is unable to complete my donation pickup,
            the reservation fee can be refunded by contacting ReSupply support.
          </ng-container>
          I agree to
          <a
            href="https://resupplyme.com/utility/donor-priority-pickups-terms-of-use"
            target="_blank"
            >ReSupply's Terms and Conditions</a
          >.
        </label>
        <p
          *ngIf="!userTerms && showErrors"
          class="invalid terms-validation-error"
          fxLayout="row"
          fxLayoutGap="2px"
          fxLayoutAlign="center center"
        >
          <mat-icon>info_outline</mat-icon>
          <span>Check this box to agree to the Terms & Conditions</span>
        </p>
      </div>
    </div>
    <div
      class="divider"
      ngClass.gt-sm="hidden"
      *ngIf="!isSmallWindowSize"
    ></div>
  </ng-template>
</ng-template>
<ng-template #STRIPE>
  <rspl-stripe
    *ngIf="
      estimate &&
      flowService.paymentIntent &&
      flowService.paymentIntent.client_secret
    "
    [paymentIntent]="flowService.paymentIntent"
    (onReady)="cardReady()"
    [type]="!!flowService.paymentIntent.booking_fee ? 'payment' : 'setup'"
    (onSuccess)="paymentSuccess()"
    (onError)="onPaymentError($event)"
    [total]="{
      amount:
        (flowService.paymentIntent.booking_fee
          ? estimate.bookingFee || 0
          : estimate.donationPrice || 0) * 100,
      label: 'Total Cost'
    }"
    [newCard]="true"
    [disableButtons]="!userTerms"
    (onDisabledButtonsClicked)="disabledButtonsClicked()"
    (onPaymentSetupSuccess)="onPaymentSetupSuccess($event)"
  ></rspl-stripe>
</ng-template>
