import {
  Component,
  EventEmitter,
  Inject,
  Input,
  OnInit,
  Output,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import {
  AppType,
  AvailabilityDto,
  Charity,
  ENVIRONMENT,
  Environment,
  InputActivity,
  PartOfDay,
  WeekAvailabilityDto,
} from '@domains';
import { CharityService, DonationsService, LeadsService } from '@rspl-api';
import * as moment from 'moment';
import { take } from 'rxjs/operators';
import { DesignService } from '../designable';
import { ResponsiveService } from '../responsive';
import { Designable } from './../designable/designable';

@Component({
  selector: 'rspl-availability',
  templateUrl: './availability.component.html',
  styleUrls: ['./availability.component.scss'],
})
export class AvailabilityComponent extends Designable implements OnInit {
  isDonor;
  isCaptain;
  app: AppType;
  @ViewChild('OutOfZone') outOfZoneTemplate!: TemplateRef<any>;
  outOfZone = false;
  public week?: number;
  public year?: number;
  public dates: AvailabilityDto[] = [];
  public isLoaded = false;
  pastDate = false;
  @Input() showSelectedDate = false;
  @Input() disableDates: string[] = [];
  @Input() disabled = false;
  selectedDateAvailability?: AvailabilityDto;
  #selectedDate: {
    date?: string;
    partOfDay?: PartOfDay;
  } = {};
  @Input() set selectedDate(selectedDate: {
    date?: string;
    partOfDay?: PartOfDay;
  }) {
    const fetchWeek = !this.#selectedDate.date && !this.#selectedDate.partOfDay;
    this.#selectedDate = selectedDate;
    if (this.selectedDate.date && this.selectedDate.partOfDay) {
      const x = this.dates.find((d) => d.date === this.selectedDate.date);
      this.selectedAvailable = x
        ? x[this.selectedDate.partOfDay]
        : this.selectedAvailable;
      this.pastDate = !moment()
        .hours(0)
        .minutes(0)
        .seconds(0)
        .milliseconds(0)
        .isSameOrBefore(moment(this.selectedDate.date));
      if (!this.pastDate && fetchWeek) {
        this.week = moment(this.selectedDate.date).week();
        this.year = moment(this.selectedDate.date).weekYear();
        if (!this.dates?.find((d) => d.date === this.selectedDate.date)) {
          this.getAvailability(false);
        }
      }
    }
  }

  get selectedDate(): {
    date?: string;
    partOfDay?: PartOfDay;
  } {
    return this.#selectedDate;
  }
  partsOfDay = PartOfDay;
  #charityId = '';
  @Input() set charityId(charityId: string) {
    this.#charityId = charityId?.toString();
    if ((this.zip?.length || 0) > 0) {
      this.getAvailability(false);
    }
  }

  get charityId(): string {
    return this.#charityId;
  }
  charity?: Charity;
  #partnerId?: string | null = '';
  @Input() set partnerId(partnerId: string | null | undefined) {
    this.#partnerId = partnerId?.toString() || null;
    if ((this.zip?.length || 0) > 0) {
      this.getAvailability(false);
    }
  }

  get partnerId(): string | null | undefined {
    return this.#partnerId;
  }
  #zip?: string | null;
  @Input() set zip(zip: string | undefined | null) {
    if (this.zip && this.zip !== zip) {
      this.selectedDate = {};
    }
    if ((zip?.length || 0) > 0) {
      this.#zip = zip;
      if (this.isDonor) {
        this.week = undefined;
        this.year = undefined;
      }
      this.getAvailability(true);
    } else {
      this.setEmptyAvailability();
    }
  }
  get zip(): string | undefined | null {
    return this.#zip;
  }
  @Input() leadId?: string;
  @Input() partnerIds?: string[];
  @Output() dateSelected = new EventEmitter();

  minDate = moment().add(-1, 'days').seconds(59).minutes(59).hours(23);
  minWeek = Math.max(moment().week(), this.minDate.week());
  minYear = Math.max(moment().weekYear(), this.minDate.weekYear());
  weekAvailability?: WeekAvailabilityDto;
  @Input() showError = false;
  selectedAvailable = true;

  @Output() isOutOfZone: EventEmitter<boolean> = new EventEmitter();

  outOfZoneDialog?: MatDialogRef<any, any>;

  constructor(
    private donationsService: DonationsService,
    protected leadsService: LeadsService,
    protected charityService: CharityService,
    private dialog: MatDialog,
    public override responsiveService: ResponsiveService,
    @Inject(ENVIRONMENT) private config: Environment,
    override designService: DesignService
  ) {
    super(designService, responsiveService);
    this.isDonor = this.config.app === AppType.DONOR;
    this.isCaptain = this.config.app === AppType.CAPTAIN;
    this.app = this.config.app;
  }

  override ngOnInit(): void {
    super.ngOnInit();
    this.setEmptyAvailability();
    if (this.charityId) {
      this.charityService.find(this.charityId).subscribe((charity) => {
        this.charity = charity;
      });
    }
  }

  setZoneAvailability(
    availability: WeekAvailabilityDto,
    logActivity = true,
    isLoaded = true
  ) {
    this.weekAvailability = availability;
    this.dates = availability.available;
    const outOfZone =
      !availability.in_vehicle_zone ||
      !availability.in_vehicle_zone ||
      (!!this.partnerId && !availability.in_vehicle_zone) ||
      (!!this.charityId && !availability.in_store_zone);

    if (!availability.in_vehicle_zone) {
      this.leadsService
        .createLeadActivity(
          this.leadId,
          InputActivity.OUT_OF_ZONE_VEHICLE,
          this.zip
        )
        .subscribe();
    }
    if (!availability.in_store_zone) {
      this.leadsService
        .createLeadActivity(
          this.leadId,
          InputActivity.OUT_OF_ZONE_STORE,
          this.zip
        )
        .subscribe();
    }

    this.isLoaded = isLoaded;
    if (this.zip) {
      if (!this.outOfZone && outOfZone) {
        this.isOutOfZone.next(true);
        this.leadsService
          .createLeadActivity(this.leadId, InputActivity.OUT_OF_ZONE, this.zip)
          .subscribe();
      } else if (this.outOfZone && !outOfZone) {
        this.isOutOfZone.next(false);
      }
      if (logActivity && !outOfZone && !!this.leadId) {
        this.leadsService
          .createLeadActivity(this.leadId, InputActivity.IN_ZONE, this.zip)
          .subscribe();
      }
    }
    this.outOfZone = outOfZone;
    if (this.isDonor && this.zip && this.outOfZone && !this.outOfZoneDialog) {
      this.outOfZoneDialog = this.dialog.open(this.outOfZoneTemplate, {});
      this.outOfZoneDialog
        .afterClosed()
        .pipe(take(1))
        .subscribe(() => (this.outOfZoneDialog = undefined));
    } else if (this.zip && !this.outOfZone && this.outOfZoneDialog) {
      this.outOfZoneDialog.close();
    }
  }

  nextWeek(numOfWeeks = 1, logFirst = false): void {
    if (this.year === undefined || this.week === undefined) return;
    this.isLoaded = false;

    const newWeek = moment()
      .year(this.year)
      .week(this.week)
      .endOf('week')
      .add(1, 'hour')
      .add(7 * (numOfWeeks - 1), 'days');
    this.week = newWeek.week();
    this.year = newWeek.weekYear();
    this.getAvailability(logFirst);
  }

  previousWeek(numOfWeeks = 1): void {
    if (
      this.year === undefined ||
      this.week === undefined ||
      (this.week <= this.minWeek && this.minYear === this.year)
    )
      return;
    this.isLoaded = false;
    const newWeek = moment()
      .year(this.year)
      .week(this.week)
      .startOf('week')
      .add(-7 * numOfWeeks, 'days');
    this.week = newWeek.week();
    this.year = newWeek.weekYear();

    this.getAvailability(false);
  }

  private logFirst(logFirst: boolean) {
    if (logFirst && this.hasAvailable() && !!this.leadId) {
      this.leadsService
        .createLeadActivity(
          this.leadId,
          InputActivity.FIRST_AVAILABLE_DATE,
          this.getFirstAvailable()
        )
        .subscribe();
    }
  }

  private getAvailability(logFirst = false) {
    if (!this.zip) return;
    this.donationsService
      .getAvailability(
        this.week,
        this.year,
        this.charityId,
        this.zip,
        this.partnerId,
        this.partnerIds
      )
      .pipe(take(1))
      .subscribe((response) => {
        this.week = Number(response.week);
        this.year = Number(response.year);
        this.setZoneAvailability(response);
        this.logFirst(logFirst);
        if (this.selectedDate?.date) {
          const date = this.dates.find(
            (x: AvailabilityDto) => x.date === this.selectedDate?.date
          );
        }
        if (this.selectedDate.date && this.selectedDate.partOfDay)
          this.selectDate(this.selectedDate.date, this.selectedDate.partOfDay);
      });
  }

  selectDate(date: string, partOfDay: PartOfDay): void {
    this.selectedDate = {};
    this.selectedDate.date = date;
    this.selectedDate.partOfDay = partOfDay;
    const x = this.dates.find((d) => d.date === date);
    this.selectedAvailable = x ? x[partOfDay] : this.selectedAvailable;
    this.pastDate = !moment()
      .hours(0)
      .minutes(0)
      .seconds(0)
      .milliseconds(0)
      .isSameOrBefore(moment(this.selectedDate.date));
    this.dateSelected.emit(this.selectedDate);
  }

  setEmptyAvailability() {
    const weekStart = (
      this.year !== undefined && this.week !== undefined
        ? moment().year(this.year).week(this.week)
        : moment()
    ).startOf('week');
    this.setZoneAvailability(
      {
        in_vehicle_zone: true,
        in_store_zone: true,
        stores: [0, 1, 2, 3, 4, 5, 6].map((i) => ({
          date: moment(weekStart).add(i, 'days').format('YYYY-MM-DD'),
          am: false,
          pm: false,
        })),
        vehicles: [0, 1, 2, 3, 4, 5, 6].map((i) => ({
          date: moment(weekStart).add(i, 'days').format('YYYY-MM-DD'),
          am: false,
          pm: false,
        })),
        available: [0, 1, 2, 3, 4, 5, 6].map((i) => ({
          date: moment(weekStart).add(i, 'days').format('YYYY-MM-DD'),
          am: false,
          pm: false,
        })),
      },
      false,
      false
    );
  }

  beforeMin(date: string): boolean {
    const d = moment(date).seconds(0).minutes(0).hours(0);
    const isBefore = d.isBefore(this.minDate);
    return isBefore;
  }

  private hasAvailable() {
    return !!this.getFirstAvailable();
  }

  private getFirstAvailable(): string | null {
    for (let i = 0; i < this.dates.length; i++) {
      const day = this.dates[i];
      if (!this.beforeMin(day.date) && (day.am || day.pm)) {
        return day.date;
      }
    }
    return null;
  }
}
