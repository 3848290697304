import { CurrencyPipe } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { ErrorHandler, NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { YouTubePlayerModule } from '@angular/youtube-player';
import { createErrorHandlerFactory } from '@appsignal/angular';
import Appsignal from '@appsignal/javascript';
import { plugin as pluginPathDecorator } from '@appsignal/plugin-path-decorator';
import { plugin as pluginWindowEvent } from '@appsignal/plugin-window-events';
import { ENVIRONMENT } from '@domains';
import { environment } from '@donor/../environments/environment';
import { AppRoutingModule } from '@donor/app-routing.module';
import { AppComponent } from '@donor/app.component';
import { AlreadyPaidComponent } from '@donor/components/already-paid/already-paid.component';
import { CategorizeHelpDialogComponent } from '@donor/components/categorize-help-dialog/categorize-help-dialog.component';
import { CreateProgressComponent } from '@donor/components/create-progress/create-progress.component';
import { DonationSpecItemComponent } from '@donor/components/donation-spec-item/donation-spec-item.component';
import { DonationSpecComponent } from '@donor/components/donation-spec/donation-spec.component';
import { InfoDialogComponent } from '@donor/components/info-dialog/info-dialog.component';
import { InfoFormComponent } from '@donor/components/info-form/info-form.component';
import { PageHeaderComponent } from '@donor/components/page-header/page-header.component';
import { ReviewComponent } from '@donor/components/review/review.component';
import { SplitScreenComponent } from '@donor/components/split-screen/split-screen.component';
import { MaterialImportsModule } from '@donor/material/material-imports.module';
import { ConfirmComponent } from '@donor/screens/confirm/confirm.component';
import { CreatedComponent } from '@donor/screens/created/created.component';
import { DonationsComponent } from '@donor/screens/donations/donations.component';
import { EditComponent } from '@donor/screens/edit/edit.component';
import { FailComponent } from '@donor/screens/fail/fail.component';
import { LookupComponent } from '@donor/screens/lookup/lookup.component';
import { CancelComponent } from '@donor/screens/manage/cancel/cancel.component';
import { EditInfoComponent } from '@donor/screens/manage/edit-info/edit-info.component';
import { EditSpecComponent } from '@donor/screens/manage/edit-spec/edit-spec.component';
import { PhotosComponent } from '@donor/screens/manage/photos/photos.component';
import { RescheduleComponent } from '@donor/screens/manage/reschedule/reschedule.component';
import { StateComponent } from '@donor/screens/manage/state/state.component';
import { NotFoundComponent } from '@donor/screens/not-found/not-found.component';
import { PaymentSuccessComponent } from '@donor/screens/payment-success/payment-success.component';
import { ReceiptComponent } from '@donor/screens/receipt/receipt.component';
import { SuccessComponent } from '@donor/screens/success/success.component';
import { RsplPaymentModule } from '@payment';
import { RsplApiModule } from '@rspl-api';
import { RsplMapModule } from '@rspl-map';
import { RsplUIModule } from '@rspl-ui';
import { RsplVersionConfig, RsplVersionModule } from '@rspl-version';
import { StarRatingModule } from 'angular-star-rating';
import { LaddaModule } from 'angular2-ladda';
import {
  CurrencyMaskConfig,
  CurrencyMaskInputMode,
  NgxCurrencyModule,
} from 'ngx-currency';
import { NgxMaskModule } from 'ngx-mask';
import { PixelModule } from 'ngx-pixel';
import { NgxPrintModule } from 'ngx-print';
import { NgxStripeModule } from 'ngx-stripe';
import { DonorScreeningComponent } from './components/donor-screening/donor-screening.component';
import { EtaComponent } from './components/eta/eta.component';
import { OrganizationLandingComponent } from './components/organization-landing/organization-landing.component';
import { DonorFlowComponent } from './screens/flow/donor-flow/donor-flow.component';
import { FlowAdditionalInformationComponent } from './screens/flow/steps/flow-additional-information/flow-additional-information.component';
import { FlowDonationDetailsComponent } from './screens/flow/steps/flow-donation-details/flow-donation-details.component';
import { FlowPaymentComponent } from './screens/flow/steps/flow-payment/flow-payment.component';
import { FlowPlaceTimeComponent } from './screens/flow/steps/flow-place-time/flow-place-time.component';
import { FlowSplitScreenComponent } from './screens/flow/steps/flow-split-screen/flow-split-screen.component';
import { EtaScreenComponent } from './screens/manage/eta-screen/eta-screen.component';

export const customCurrencyMaskConfig: CurrencyMaskConfig = {
  align: 'left',
  allowNegative: false,
  allowZero: true,
  decimal: '.',
  precision: 2,
  prefix: '$ ',
  suffix: '',
  thousands: ',',
  nullable: false,
  min: 0,
  max: undefined,
  inputMode: CurrencyMaskInputMode.NATURAL,
};

const appsignal = new Appsignal({
  key: environment.appSignal,
  namespace: 'donor',
  revision: '{{POST_BUILD_ENTERS_APP_SIGNAL_HASH_HERE}}',
});
appsignal.use(pluginPathDecorator());
appsignal.use(pluginWindowEvent());

@NgModule({
  declarations: [
    AppComponent,
    DonationSpecItemComponent,
    SuccessComponent,
    FailComponent,
    ConfirmComponent,
    RescheduleComponent,
    CancelComponent,
    PaymentSuccessComponent,
    NotFoundComponent,
    SplitScreenComponent,
    CreateProgressComponent,
    InfoDialogComponent,
    StateComponent,
    AlreadyPaidComponent,
    CreatedComponent,
    ReceiptComponent,
    DonationsComponent,
    LookupComponent,
    DonationSpecComponent,
    EditSpecComponent,
    EditInfoComponent,
    InfoFormComponent,
    ReviewComponent,
    PhotosComponent,
    CategorizeHelpDialogComponent,
    PageHeaderComponent,
    DonorScreeningComponent,
    OrganizationLandingComponent,
    EditComponent,
    EtaScreenComponent,
    EtaComponent,
    DonorFlowComponent,
    FlowSplitScreenComponent,
    FlowDonationDetailsComponent,
    FlowPlaceTimeComponent,
    FlowAdditionalInformationComponent,
    FlowPaymentComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FlexLayoutModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    LaddaModule.forRoot({
      style: 'zoom-in',
    }),
    NgxMaskModule.forRoot(),
    StarRatingModule.forRoot(),
    BrowserAnimationsModule,
    MaterialImportsModule,
    NgxCurrencyModule.forRoot(customCurrencyMaskConfig),
    PixelModule.forRoot({
      enabled: environment.production,
      pixelId: '271975238257416',
    }),
    NgxStripeModule.forRoot(environment.stripeKey),
    NgxPrintModule,
    YouTubePlayerModule,
    RsplApiModule.forRoot(),
    RsplVersionModule.forRoot({
      currentHash: '{{POST_BUILD_ENTERS_HASH_HERE}}',
    } as RsplVersionConfig),
    RsplUIModule.forRoot(),
    RsplMapModule.forRoot(),
    RsplPaymentModule.forRoot(environment),
  ],
  providers: [
    CurrencyPipe,
    ...(environment.isProductionBuild ||
    environment.isStagingBuild ||
    environment.isDevBuild
      ? [
          {
            provide: ErrorHandler,
            useFactory: createErrorHandlerFactory(appsignal),
          },
        ]
      : []),
    { provide: ENVIRONMENT, useValue: environment },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
