<app-page-header
  title="Edit your Donation Pickup"
  logo="assets/images/rspl_logo.png"
  [charity]="donation?.charity"
>
</app-page-header>
<div class="page-content zoom" *ngIf="donation">
  <div fxLayout="column" class="manage-wrapper" fxLayoutGap="20px">
    <mat-accordion
      fxFlex="100"
      fxFill
      [multi]="false"
    >
      <mat-expansion-panel>
        <mat-expansion-panel-header>
          <mat-panel-title><mat-icon svgIcon="edit-icon-blue"></mat-icon> Edit your information</mat-panel-title>
        </mat-expansion-panel-header>
        <div class="edit-section">
          <app-info-form [form]="form"></app-info-form>
          <div fxLayout="row" fxLayoutAlign="end center">
            <button
              class="primary-btn filled-btn"
              (click)="saveDonorInformation()"
              [ladda]="isSubmittingDonorInformation"
            >
              Save
            </button>
          </div>
        </div>
        <div *ngIf="donorInformationSaved" class="success-msg" fxLayout="row" fxLayoutAlign="space-between center">
          <span>Your information edited successfully!</span>
          <mat-icon svgIcon="success-icon"></mat-icon>
        </div>
      </mat-expansion-panel>
      <mat-expansion-panel>
        <mat-expansion-panel-header>
          <mat-panel-title><mat-icon svgIcon="parachute-icon"></mat-icon> Edit donation items</mat-panel-title>
        </mat-expansion-panel-header>
        <div class="edit-section zoom" fxLayout="column" fxLayoutAlign="center center">
          <app-donation-spec
            *ngIf="donation && donation.pricing"
            [charityId]="donation.charity?.id || ''"
            [specification]="specification"
            [totalCostEstimate]="totalCostEstimate"
            [pricing]="donation.pricing"
            (onSpecificationChange)="onSpecificationChange($event)"
            [showFee]="false"
            [showPrice]="false"
            [submitColorPrimary]="true"
            saveLabel="Save"
            (onSave)="saveDonationSpec()"
          >
          </app-donation-spec>
        </div>
        <div *ngIf="donationSpecChanged" class="success-msg" fxLayout="row" fxLayoutAlign="space-between center">
          <span>Donation items edited successfully!</span>
          <mat-icon svgIcon="success-icon"></mat-icon>
        </div>
      </mat-expansion-panel>
      <mat-expansion-panel [disabled]="[
        partnerStates.en_routed_to_store,
        partnerStates.arrived_at_store,
        partnerStates.completed,
        ].includes(donation?.partnerState)">
        <mat-expansion-panel-header>
          <mat-panel-title><mat-icon svgIcon="calendar-icon"></mat-icon>Change donation date</mat-panel-title>
        </mat-expansion-panel-header>
        <div class="edit-section" fxLayout="column">
          <rspl-availability
            *ngIf="donation"
            [charityId]="donation.charity?.id || ''"
            (dateSelected)="onDateSelected($event)"
            [selectedDate]="selectedDate"
            [zip]="donation.address.zip"
            fxFlex="100"
            [showSelectedDate]="true"
            [disableDates]="[donation.date]"
          ></rspl-availability>
          <div fxLayout="row" fxLayoutAlign="end center">
            <button
              class="primary-btn filled-btn"
              rsplDisableDoubleClick
              (oneClick)="saveDonationDate()"
              [ladda]="isSubmittingDonorInformation"
            >
              Save
            </button>
          </div>
        </div>
        <div *ngIf="donationDateChanged" class="success-msg" fxLayout="row" fxLayoutAlign="space-between center">
          <span>Donation date changed successfully!</span>
          <mat-icon svgIcon="success-icon"></mat-icon>
        </div>
      </mat-expansion-panel>
    </mat-accordion>
    <button
      class="primary-page-btn block-btn"
      fxLayout="row"
      fxLayoutAlign="center center"
      fxLayoutGap="10px"
      [routerLink]="['/', 'i', donationCode, 'photos']"
      fxFlex="100"
    >
      <mat-icon svgIcon="camera-icon"></mat-icon>
      <span>Add photos to help driver</span>
    </button>
    <button
      class="primary-page-btn red-btn block-btn"
      [routerLink]="['/', 'i', donation.donationCode]"
      fxFlex="100"
    >
      Done
    </button>
  </div>
</div>


