<ng-container *ngIf="design" [ngTemplateOutlet]="DESIGN_2"></ng-container>
<ng-template #DESIGN_2>
  <app-page-header [charity]="donation?.charity" [title]="stepTitle" [logo]="headerLogo"> </app-page-header>
  <div class="page-content state-page">
    <div fxLayout="column" class="manage-wrapper" fxLayoutGap="20px">
      <div class="card-wrapper" fxLayout="column">
        <div>
          <mat-stepper
            [linear]="true"
            orientation="horizontal"
            #stepper
            [selectedIndex]="step"
            *ngIf="
              step < 5 && donation?.partnerState !== partnerStates.canceled
            "
          >
            <mat-step [editable]="false" [completed]="step > 0">
              <ng-template matStepLabel>
                <div
                  fxLayout="row"
                  fxLayoutAlign="start center"
                  fxLayoutGap="5px"
                >
                  <span>Pickup reserved</span>
                </div>
              </ng-template>
            </mat-step>
            <mat-step [editable]="false" [completed]="step > 1">
              <ng-template matStepLabel>
                <div
                  fxLayout="row"
                  fxLayoutAlign="start center"
                  fxLayoutGap="5px"
                >
                  <span>Driver assigned</span>
                </div>
              </ng-template>
            </mat-step>
            <mat-step [editable]="false" [completed]="step > 2">
              <ng-template matStepLabel>
                <div
                  fxLayout="row"
                  fxLayoutAlign="start center"
                  fxLayoutGap="5px"
                >
                  <span>Driver on the way</span>
                </div>
              </ng-template>
            </mat-step>
            <mat-step [editable]="false" [completed]="step > 3">
              <ng-template matStepLabel>
                <div
                  fxLayout="row"
                  fxLayoutAlign="start center"
                  fxLayoutGap="5px"
                >
                  <span>Confirm payment</span>
                </div>
              </ng-template>
            </mat-step>
            <mat-step [editable]="false" [completed]="step > 4">
              <ng-template matStepLabel>
                <div
                  fxLayout="row"
                  fxLayoutAlign="start center"
                  fxLayoutGap="5px"
                >
                  <span>Thank You</span>
                </div>
              </ng-template>
            </mat-step>
            <ng-template matStepperIcon="done" let-index="index">
              {{ index + 1 }}
            </ng-template>
          </mat-stepper>
        </div>
        <h1 *ngIf="step === 0" class="text-center step-title">
          Awaiting Driver Assignment
        </h1>
        <h1 *ngIf="step === 1" class="text-center step-title">
          Your driver will notify you when on the way
        </h1>
        <h1 *ngIf="step === 2" class="text-center step-title">
          We are on the way to you!
        </h1>
        <h1 *ngIf="step === 3" class="text-center step-title">
          Please accept the final pickup cost
        </h1>
        <h1 *ngIf="step === 4" class="text-center step-title">
          Access your receipts below
        </h1>
        <h1
          *ngIf="donation?.donorState === donorStates.canceled"
          class="text-center step-title"
        >
          You have canceled this donation
        </h1>
        <h1
          *ngIf="donation?.partnerState === partnerStates.canceled"
          class="text-center step-title"
        >
          We are so sorry, but unfortunately your donation has been canceled
        </h1>
        <div class="divider"></div>
        <div
          class="buttons"
          fxLayout="row"
          fxLayoutAlign="center center"
          *ngIf="donation?.donorState !== donorStates.canceled"
        >
          <div
            fxLayout="row"
            fxFlex
            fxLayoutAlign="center center"
            style="margin-bottom: 0"
          >
            <button
              class="primary-page-btn success-btn green-btn"
              *ngIf="
                step === 1 &&
                isDayBefore &&
                donation?.donorState !== donorStates.confirmed
              "
              (click)="confirm()"
              [disabled]="isSubmitting"
              fxFlex="100"
              style="margin-top: 15px"
            >
              Confirm your pickup
            </button>
            <button
              class="primary-page-btn success-btn green-btn"
              *ngIf="
                donation?.donorState === donorStates.confirmed && step === 1
              "
              fxFlex="100"
              fxLayout="row"
              fxLayoutGap="10px"
              fxLayoutAlign="center center"
            >
              <span>PICKUP CONFIRMED</span>
              <mat-icon svgIcon="success-icon"></mat-icon>
            </button>
          </div>
        </div>
        <p *ngIf="step === 0" class="step-description">
          You will receive a confirmation email shortly. Revisit this page any
          time through that email. Your driver assignment may take a bit of
          time, we'll notify you when your driver has been assigned.
        </p>
        <p *ngIf="step === 1" class="step-description">
          We'll see you on your pick up date! Resupply contracts licensed,
          trained, independent companies and drivers to pickup and deliver your
          donations to Charity. When the driver is on his way, you will be able
          to see a precise ETA on this screen.
        </p>
        <p *ngIf="step === 2" class="step-description">
          Your Driver is on his way! Once your driver arrives, if you have any
          issues, please feel free to contact your Driver's boss or to text
          Resupply support at the phone number below.
        </p>
        <p *ngIf="step === 3" class="step-description">
          Please make sure to show your driver ALL donation items that you would
          like to be picked up. Once the walkthrough is complete, you must
          accept the final price quote for the driver to continue.
        </p>
        <p *ngIf="step === 4" class="step-description">
          Please note that your card has been temporarily authorized. You should
          see a final charge reflected on your statement within 7 business days.
          When filling out your tax receipt, please enter the names of your
          donated items and use your best judgment to value them. If you need
          additional guidance on valuing your donated items, please utilize the
          official IRS Guide via the link on your tax receipt.
        </p>
        <p
          *ngIf="this.donation?.donorState === this.donorStates.canceled"
          class="step-description"
        >
          Your donation has been successfully canceled, please feel free to chat
          with ReSupply support if you need additional assistance.
        </p>
        <p
          *ngIf="this.donation?.partnerState === this.partnerStates.canceled"
          class="step-description"
        >
          {{ donation?.donor?.name }}, due to issues with coordinating your
          donation pickup request with a nearby driver, we regret to inform you
          that your request has been canceled. We sincerely apologize for being
          unable to fulfill your pickup. We understand that you may be relying
          on ReSupply during stressful times, and we'd like to express our
          deepest apologies for being unable to fulfill your request. Please
          note that no fault lies with the 501c3 charity you were attempting to
          donate to, and you will receive a refund of your booking fee within
          7-10 business days. If there's anything we can do to assist you or if
          you have any questions, please don't hesitate to reach out to us using
          the chat icon below. We are here to support you in any way we can.
        </p>
        <div>
          <div fxLayout="column" fxLayoutAlign="center center" *ngIf="donation">
            <div
              class="main-donation-details"
              *ngIf="
                donation?.donorState === donorStates.canceled ||
                donation?.partnerState === partnerStates.canceled ||
                step < 3
              "
            >
              <app-eta *ngIf="(donation && step === 1 || donation && step === 2) && (isSameDay || donation.eta)" [donation]="donation"></app-eta>
              <div class="eta-map" *ngIf="showMap && donation && step === 2 && isSameDay && !!partner?.meta?.etaTrackingEnabled">
                <rspl-map
                  [showDonations]="true"
                  [showZoom]="false"
                  [showDonationPopup]="false"
                  [showAddressSearch]="false"
                  [showTrucks]="!!truck"
                  [showTruckPopup]="false"
                  [showDonationStateColor]="false"
                  [donations]="[donation]"
                  [trucks]="truck ? [truck] : undefined"
                ></rspl-map>
              </div>
              <div fxLayout="column">
                <div fxLayout="column">
                  <span>Charity Name:</span>
                  <span class="input-feel">{{ donation.charity?.name || '/' }}</span>
                </div>
                <div fxLayout="column">
                  <span>When:</span>
                  <span class="input-feel"
                    >{{ donation.date | date : 'MMM d, y' }} between
                    {{
                      donation.partOfDay === 'am'
                        ? '8:00 AM - 12:00 PM'
                        : '12:00 PM - 4:00 PM'
                    }}</span
                  >
                </div>
                <div fxLayout="column">
                  <span>Confirmation Code:</span>
                  <span class="input-feel">{{ donation.donationCode }}</span>
                </div>
              </div>
            </div>
            <div
              class="buttons"
              fxLayout="row"
              fxLayoutAlign="center center"
              style="margin-top: 15px"
              *ngIf="donation?.donorState !== donorStates.canceled"
            >
              <div
                fxLayout="row"
                fxFlex
                fxLayoutAlign="center center"
                *ngIf="donation && step === 1"
              >
                <button
                  *ngIf="donation && step === 1"
                  class="primary-page-btn light-btn block-btn"
                  (click)="addToCalendar()"
                  fxFlex="100"
                  style="margin-bottom: 10px"
                >
                  Add to Calendar
                </button>
              </div>
            </div>
            <div
              class="buttons"
              fxLayout="row"
              fxLayoutGap="10px"
              fxLayoutAlign="center center"
              *ngIf="donation?.donorState !== donorStates.canceled"
            >
              <div
                fxFlex
                *ngIf="
                  donation &&
                  step < 3 &&
                  donation?.partnerState !== partnerStates.completed &&
                  donation.donorState !== donorStates.canceled &&
                  donation.partnerState !== partnerStates.canceled &&
                  !(
                    donation.payment?.authCompleted ||
                    donation.payment?.completed
                  )
                "
              >
                <a
                  fxFlex="100"
                  class="primary-page-btn block-btn"
                  fxLayout="row"
                  fxLayoutAlign="center center"
                  fxLayoutGap="5px"
                  [routerLink]="['/', 'i', donationCode, 'cancel']"
                  [queryParams]="queryParams"
                  (click)="onCancelOptionClick()"
                  [ngClass]="{ disabled: isSubmitting }"
                >
                  <mat-icon svgIcon="close-icon">cancel</mat-icon>
                  <span>Cancel donation</span>
                </a>
              </div>
              <div
                fxFlex
                *ngIf="
                  donation &&
                  donation?.partnerState !== partnerStates.completed &&
                  step < 3
                "
              >
                <a
                  *ngIf="
                    donation.donorState !== donorStates.canceled &&
                    donation.partnerState !== partnerStates.canceled
                  "
                  fxFlex="100"
                  class="primary-page-btn gray-btn block-btn"
                  fxLayout="row"
                  fxLayoutAlign="center center"
                  fxLayoutGap="5px"
                  [routerLink]="['/', 'e', donation.donationCode]"
                >
                  <mat-icon svgIcon="edit-icon"></mat-icon>
                  <span>Edit My Pickup</span>
                </a>
              </div>
              <div fxFlex *ngIf="donation && step === 3">
                <a
                  *ngIf="
                    donation.donorState !== donorStates.canceled &&
                    donation.partnerState !== partnerStates.canceled
                  "
                  fxFlex="100"
                  class="primary-page-btn gray-btn block-btn"
                  [routerLink]="['/', 'd', donationCode]"
                >
                  Payment Confirmation
                </a>
              </div>

              <div fxFlex="50" *ngIf="step === 4">
                <a
                  fxFlex="100"
                  class="primary-page-btn gray-btn block-btn"
                  [href]="taxReceiptUrl + donation.donationCode"
                  (click)="onTaxReceiptOptionClick()"
                  target="_blank"
                  >Tax receipt</a
                >
              </div>
              <div fxFlex="50" *ngIf="step === 4">
                <a
                  fxFlex="100"
                  class="primary-page-btn gray-btn block-btn"
                  [routerLink]="['/', 'receipt', donation.donationCode]"
                  (click)="onReceiptOptionClick()"
                  target="_blank"
                >
                  Receipt</a
                >
              </div>
              <div fxFlex="100" *ngIf="donation.rescheduleAvailable && donation.partnerState === partnerStates.canceled">
                <a
                  fxFlex="100"
                  class="primary-page-btn gray-btn block-btn"
                  [routerLink]="['/', 'i', donation.donationCode, 'reschedule']"
                >
                  Reschedule</a
                >
              </div>
            </div>
            <!--            <div-->
            <!--              class="card"-->
            <!--              *ngIf="step === 4 && !donation?.review"-->
            <!--              fxFlex="100"-->
            <!--            >-->
            <!--              <p *ngIf="review.submitted">Thank You!</p>-->
            <!--              <app-review #review [donation]="donation"></app-review>-->
            <!--              <button-->
            <!--                *ngIf="!review.submitted"-->
            <!--                class="primary-page-btn gray-btn block-btn"-->
            <!--                [disabled]="review.form.invalid"-->
            <!--                (click)="review.submit()"-->
            <!--              >-->
            <!--                Submit review-->
            <!--              </button>-->
            <!--            </div>-->
            <mat-accordion fxFlex="100" fxFill>
              <mat-expansion-panel>
                <mat-expansion-panel-header>
                  <mat-panel-title> More details</mat-panel-title>
                </mat-expansion-panel-header>
                <div>
                  <div fxLayout="column">
                    <div fxFlex.gt-xs="50" fxLayout="column">
                      <div class="card-wrapper" fxLayout="column">
                        <h2 class="card-title" fxFlexAlign="start">
                          Donor info
                        </h2>
                        <div fxLayout="column">
                          <div
                            fxLayout.gt-sm="row"
                            fxLayout.lt-md="column"
                            fxLayoutGap.gt-sm="10px"
                            fxLayoutGap.lt-md="0"
                          >
                            <span>Name:</span>
                            <span>{{ donation.donor.name }}</span>
                          </div>
                          <div
                            fxLayout.gt-sm="row"
                            fxLayout.lt-md="column"
                            fxLayoutGap.gt-sm="10px"
                            fxLayoutGap.lt-md="0"
                          >
                            <span>Phone:</span>
                            <span>{{ formattedDonorPhoneValue }}</span>
                          </div>
                          <div
                            fxLayout.gt-sm="row"
                            fxLayout.lt-md="column"
                            fxLayoutGap.gt-sm="10px"
                            fxLayoutGap.lt-md="0"
                          >
                            <span>Email:</span>
                            <span>{{ donation.donor.email }}</span>
                          </div>
                        </div>
                      </div>

                      <div class="card-wrapper" fxLayout="column">
                        <h2 class="card-title" fxFlexAlign="start">
                          Donation info
                        </h2>
                        <div fxLayout="column">
                          <div
                            fxLayout.gt-sm="row"
                            fxLayout.lt-md="column"
                            fxLayoutGap.gt-sm="10px"
                            fxLayoutGap.lt-md="0"
                          >
                            <span>Date:</span>
                            <span>{{ donation.date | date : 'MMM d, y' }}</span>
                          </div>
                          <div
                            fxLayout.gt-sm="row"
                            fxLayout.lt-md="column"
                            fxLayoutGap.gt-sm="10px"
                            fxLayoutGap.lt-md="0"
                          >
                            <span>Time:</span>
                            <span>{{ donation.partOfDay | uppercase }}</span>
                          </div>
                          <div
                            fxLayout.gt-sm="row"
                            fxLayout.lt-md="column"
                            fxLayoutGap.gt-sm="10px"
                            fxLayoutGap.lt-md="0"
                          >
                            <span>Address:</span>
                            <span>{{ fullDonorAddress }}</span>
                          </div>
                          <div
                            fxLayout.gt-sm="row"
                            fxLayout.lt-md="column"
                            fxLayoutGap.gt-sm="10px"
                            fxLayoutGap.lt-md="0"
                          >
                            <span>Donation items description:</span>
                            <span>{{ donation.donationItems || '/' }}</span>
                          </div>
                          <div
                            fxLayout.gt-sm="row"
                            fxLayout.lt-md="column"
                            fxLayoutGap.gt-sm="10px"
                            fxLayoutGap.lt-md="0"
                          >
                            <span>Additional driver instructions:</span>
                            <span>{{ donation.instructions || '/' }}</span>
                          </div>
                        </div>
                      </div>

                      <div class="card-wrapper" fxLayout="column">
                        <h2
                          class="card-title"
                          fxFlexAlign="start"
                          *ngIf="step === 2 && donation?.vehicle"
                        >
                          Truck details
                        </h2>
                        <div
                          class="vehicle"
                          fxLayout="column"
                          fxFlexAlign="center center"
                          fxLayoutGap="15px"
                          *ngIf="step === 2 && donation?.vehicle"
                        >
                          <div
                            fxLayout="row"
                            fxFlexAlign="center center"
                            fxLayoutGap="10px"
                            fxFlex="100"
                          >
                            <span fxFlex="120px">Company:</span>
                            <span fxFlex>{{ donation.partner?.name }}</span>
                          </div>
                          <div
                            fxLayout="row"
                            fxFlexAlign="center center"
                            fxLayoutGap="10px"
                            fxFlex="100"
                          >
                            <span fxFlex="120px">Licence plate:</span>
                            <span fxFlex>{{
                              donation.vehicle?.licensePlate
                            }}</span>
                          </div>
                          <div
                            fxLayout="row"
                            fxFlexAlign="center center"
                            fxLayoutGap="10px"
                          >
                            <span fxFlex="120px">Make:</span>
                            <span fxFlex>{{ donation.vehicle?.make }}</span>
                          </div>
                          <div
                            fxLayout="row"
                            fxFlexAlign="center center"
                            fxLayoutGap="10px"
                          >
                            <span fxFlex="120px">Model:</span>
                            <span fxFlex>{{ donation.vehicle?.model }}</span>
                          </div>
                        </div>
                      </div>
                      <div class="card-wrapper" fxLayout="column">
                        <h2 class="card-title" fxFlexAlign="start">Support</h2>
                        <div fxLayout="column">
                          <div
                            fxLayout.gt-sm="row"
                            fxLayout.lt-md="column"
                            fxLayoutGap.gt-sm="10px"
                            fxLayoutGap.lt-md="0"
                          >
                            <span>Phone:</span>
                            <span>{{ formattedSupportPhoneValue }}</span>
                          </div>
                          <div
                            fxLayout.gt-sm="row"
                            fxLayout.lt-md="column"
                            fxLayoutGap.gt-sm="10px"
                            fxLayoutGap.lt-md="0"
                          >
                            <span>Email:</span>
                            <span>donations@resupplyme.com</span>
                          </div>
                        </div>
                      </div>

                      <div
                        class="card-wrapper"
                        fxLayout="column"
                        *ngIf="donation?.charity"
                      >
                        <h2 class="card-title" fxFlexAlign="start">Charity</h2>
                        <div fxLayout="column">
                          <div
                            fxLayout.gt-sm="row"
                            fxLayout.lt-md="column"
                            fxLayoutGap.gt-sm="10px"
                            fxLayoutGap.lt-md="0"
                          >
                            <span>Name:</span>
                            <span>{{ donation.charity?.name || '/' }}</span>
                          </div>
                          <div
                            fxLayout.gt-sm="row"
                            fxLayout.lt-md="column"
                            fxLayoutGap.gt-sm="10px"
                            fxLayoutGap.lt-md="0"
                          >
                            <span>EIN:</span>
                            <span>{{ donation.charity?.ein }}</span>
                          </div>
                          <div
                            fxLayout.gt-sm="row"
                            fxLayout.lt-md="column"
                            fxLayoutGap.gt-sm="10px"
                            fxLayoutGap.lt-md="0"
                          >
                            <span>Address:</span>
                            <span>{{ fullCharityAddress }}</span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div fxFlex.gt-xs="50" fxLayout="column">
                      <div
                        class="card-wrapper"
                        fxLayout="column"
                        *ngIf="donation"
                      >
                        <h2 class="card-title" fxFlexAlign="start">
                          Initial estimate
                        </h2>
                        <div fxLayout="row" fxLayout.xs="column">
                          <rspl-donation-estimate
                            [pricing]="donation.pricing"
                            [specification]="donation.specification"
                            [showUnitPrices]="false"
                            [showZeros]="false"
                            [bookingFee]="donation.pricing.bookingFee"
                            totalLabel="Estimated Pickup Cost (Due On-Site)"
                            bookingLabel="Booking Fee (<b>Paid</b>)"
                          ></rspl-donation-estimate>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </mat-expansion-panel>
            </mat-accordion>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>
