import { Component, Inject, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {
  ButtonActivity,
  Charity,
  DonorFlowStepConfig,
  ENVIRONMENT,
  Environment,
  FlowStep,
  FlowTypes,
  Lead,
  Organization,
  PageActivity,
  Pricing
} from '@domains';
import { AppService } from '@donor/app.service';
import { Designable, DesignService, ResponsiveService } from '@rspl-ui';
import { take } from 'rxjs';
import { FlowService } from '../donor-flow.service';

@Component({
  template: '',
})
export abstract class BaseFlowStepComponent
  extends Designable
  implements OnInit
{
  @Input() charity?: Charity;
  @Input() partnerId?: string;
  @Input() organization?: Organization;
  @Input() flowConfiguration: DonorFlowStepConfig[];
  @Input() flowStep: FlowStep;
  @Input() flowId: string;
  @Input() flowType: FlowTypes;

  showErrors: boolean = false;
  isSubmitting: boolean = false;
  apiError: any;
  onSaveSuccess: () => void;

  get lead(): Lead {
    return this.flowService.lead;
  }

  get leadId(): string {
    return this.flowService.lead.id;
  }

  get pricing(): Pricing {
    return this.flowService.lead.pricing;
  }

  #currentStepIndex: number;
  get currentStepIndex(): number {
    if (this.#currentStepIndex === undefined) {
      this.#currentStepIndex = this.flowConfiguration.findIndex(
        (step) => step.step === this.flowStep
      );
    }
    return this.#currentStepIndex;
  }

  #nextStep: DonorFlowStepConfig;
  get nextStep(): DonorFlowStepConfig {
    if (this.#nextStep === undefined) {
      this.#nextStep =
        this.flowConfiguration[this.currentStepIndex + 1] || null;
    }
    return this.#nextStep;
  }

  #previousStep: DonorFlowStepConfig;
  get previousStep(): DonorFlowStepConfig {
    if (this.#previousStep === undefined) {
      this.#previousStep =
        this.flowConfiguration[this.currentStepIndex - 1] || null;
    }
    return this.#previousStep;
  }

  constructor(
    protected flowService: FlowService,
    protected appService: AppService,
    protected router: Router,
    protected route: ActivatedRoute,
    override designService: DesignService,
    override responsiveService: ResponsiveService,
    @Inject(ENVIRONMENT) protected environment: Environment
  ) {
    super(designService, responsiveService);
  }

  override ngOnInit(): void {
    super.ngOnInit();
    if (this.pageVisitActivity())
      this.appService
        .createLeadActivity(this.lead.id, this.pageVisitActivity())
        .pipe(take(1))
        .subscribe();
  }

  saveLead() {
    if (!this.isValid()) {
      this.onError();
      return;
    }
    this.isSubmitting = true;
    this.showErrors = false;
    this.apiError = undefined;
    if (this.saveButtonActivity())
      this.appService
        .createLeadActivity(this.leadId, this.saveButtonActivity())
        .pipe(take(1))
        .subscribe();
    this.flowService
      .updateLead(this.getFromValue())
      .pipe(take(1))
      .subscribe({
        next: (lead) => {
          this.isSubmitting = false;
          if (this.onSaveSuccess) {
            this.onSaveSuccess();
          } else {
            const next = this.nextStep;
            if (next) {
              this.router.navigate(
                ['/', next.step, this.flowType, this.flowId],
                {
                  queryParams: this.route.snapshot.queryParams,
                }
              );
            }
          }
        },
        error: (err) => {
          this.isSubmitting = false;
          this.apiError = err;
          this.onError();
        },
      });
  }

  get hasBack(): boolean {
    return !!this.previousStep;
  }

  back() {
    const previous = this.previousStep;
    if (previous) {
      if (this.isValid()) {
        this.flowService.lead = new Lead({
          ...this.flowService.lead,
          ...this.getFromValue(),
        });
      }
      this.router.navigate(['/', previous.step, this.flowType, this.flowId], {
        queryParams: this.route.snapshot.queryParams,
      });
    }
  }

  private onError() {
    this.showErrors = true;
    this.showError();
  }

  static isCompleted(lead: Lead): boolean {
    throw new Error('Method "isCompleted" not implemented!');
  }

  abstract isValid(): boolean;
  abstract getFromValue(): Partial<Lead>;
  abstract showError(): void;
  abstract saveButtonActivity(): ButtonActivity;
  abstract pageVisitActivity(): PageActivity;
}
