<ng-container *ngIf="design" [ngTemplateOutlet]="DESIGN_2"></ng-container>
<ng-template #DESIGN_2>
  <app-page-header
    [charity]="charity"
    [territory]="organization"
    title="Enter your address & choose a time slot."
    subtitle="Start scheduling your Priority Pick-up."
    [hasBack]="hasBack"
    (onBack)="back()"
  ></app-page-header>
  <div class="page-content" fxLayout="column" fxLayoutAlign="start start">
    <div
      class="scheduling step-content"
      fxLayoutAlign="center center"
      fxLayoutAlign.xs="center start"
    >
      <form>
        <div
          fxLayout="column"
          fxLayout.gt-sm="row"
          fxLayoutAlign.gt-sm="center center"
        >
          <div fxLayout="row" fxLayoutGap="10px" fxFlex>
            <div
              class="form-row address"
              [ngClass]="{ invalid: showErrors && !address.isValid }"
              fxFlex="100"
            >
              <rspl-address-input-form
                [address]="address"
                (addressChange)="
                  onAddressChanged($event.address, $event.manual)
                "
                (addressError)="onAddressError($event)"
                [leadId]="leadId"
                label="Enter Address Here"
                [showError]="showErrors && !address.isValid"
                (onAddressPopupOpened)="onAddressPopupOpened()"
              ></rspl-address-input-form>
            </div>
          </div>
          <div class="availability">
            <p class="selected-date-label date" fxLayoutAlign="start start">
              Selected Date:
              <span [ngClass]="{ error: !selectedDate?.date }">
                {{
                  selectedDate?.date
                    ? (selectedDate?.date | date : 'MMM d, y')
                    : 'Please select date'
                }}</span
              >
            </p>
            <p
              class="selected-date-label time"
              fxLayoutAlign="start start"
              *ngIf="selectedDate?.date"
            >
              Selected Time:
              <span *ngIf="selectedDate?.date">
                {{
                  selectedDate?.partOfDay === partsOfDay.am
                    ? '08:00 AM - 12:00 PM'
                    : ''
                }}
                {{
                  selectedDate?.partOfDay === partsOfDay.pm
                    ? '12:00 PM - 04:00 PM'
                    : ''
                }}
              </span>
            </p>
          </div>
        </div>
        <div
          class="availability-wrapper availability"
          [ngClass]="{ 'availability-disabled': !address }"
        >
          <rspl-availability
            [ngClass]="{ disabled: !address.zip }"
            [partnerIds]="
              charity
                ? environment.special.smallItemPickupCharityPartners[charity.id]
                : undefined
            "
            [charityId]="charity?.id"
            [partnerId]="partnerId"
            [leadId]="lead?.id"
            [selectedDate]="{ date: lead?.date, partOfDay: lead?.partOfDay }"
            [zip]="address?.zip"
            (dateSelected)="onDateSelected($event)"
            [showError]="address.isValid && showErrors"
          ></rspl-availability>
        </div>
        <div fxLayoutAlign="center center">
          <button
            id="step2Submission"
            class="primary-btn filled-btn warn-btn"
            (click)="saveLead()"
            type="submit"
            [ladda]="isSubmitting"
          >
            Next Step
          </button>
        </div>
      </form>
    </div>
    <div fxLayout="column" fxLayout.gt-sm="row" class="progress-wrapper">
      <app-create-progress
        fxFlex
        [step]="flowStep"
        [flowConfiguration]="flowConfiguration"
      ></app-create-progress>
    </div>
  </div>
</ng-template>
